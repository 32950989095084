import { View } from '@adac/core-view';
import styled from 'styled-components';

export const StyledPaginationtForList = styled(View)`
  [class*="MuiTablePagination-root"] {
    display: flex;

    [class*="MuiTablePagination-toolbar"] {
      display: flex;
      flex: 1 1 100%;
    }
    [class*="PaginationActions-actions"] {
      display: flex;
      flex: 1 1 auto;
      min-width: 550px;
      justify-content: space-between;
    }
    .page-number {
      font-weight: bold;
      font-size: 20px;
      /* span[class*="MuiButton-label"] {
        border: solid 1px lightgray;
        border-radius: 4px;
      }*/
      &[class*="MuiButton-textPrimary"] {
        font-weight: normal;
        font-size: inherit;
        /*
        span[class*="MuiButton-label"] {
          border: none;
        }
        */
      }
    }
    .previous-page {
      margin-right: auto;
      flex: 0 0 max-content;
    }
    .next-page {
      margin-left: auto;
      flex: 0 0 max-content;
    }
   }
`;
