import { Field, FieldProps, useFormikContext } from 'formik';
import {
  CheckBoxGroup,
  CheckboxItem, CheckBoxItem, ErrorMessage, Text, TextField,
  View,
} from '@adac/core-view';
import { __ } from '@adac/core-model';
import styled from 'styled-components';
import { EditReportImageFormValues } from './EditReportModal';

const ReasonFormContainer = styled(View)`
  margin-top: 50px;
`;

const reasonItems = [
  'Identification document recognizable',
  'Person/face recognizable',
  'Doorbell or nameplate recognizable',
  'Location recognizable',
  'other',
];

export function ReasonForm() {
  const {
    values, errors, setFieldValue,
  } = useFormikContext<EditReportImageFormValues>();

  const itemsWithControl = reasonItems.map(item => ({
    id: item,
    value: item,
    labelTitle: __(`reason:${item}`),
    checked: !!values.reason.includes(item),
    setChecked: () => {
      if (!values.reason.includes(item)) {
        setFieldValue('reason', [...values.reason, item]);
      } else {
        setFieldValue('reason', values.reason.filter((reason) => reason !== item));
      }
    },
  }) as CheckBoxItem);

  return (
    <ReasonFormContainer>
      <Text bold>{__('What is the reason for deleting the previously selected components')}</Text>
      <Field
        name="reason"
        render={({ field }: FieldProps<'reason', EditReportImageFormValues>) => (
          <CheckBoxGroup
            {...field}
            items={itemsWithControl}
            renderItem={CheckboxItem}
          />
        )}
      />
      {values.reason.includes('other') && (
      <Field
        name="otherReason"
        render={({ field }: FieldProps<'otherReason', EditReportImageFormValues>) => (
          <TextField
            isValid={!errors.otherReason}
            {...field}
          />
        )}
      />
      )}
      {errors.otherReason && <ErrorMessage>{__(errors.otherReason)}</ErrorMessage>}
    </ReasonFormContainer>
  );
}
