import { Button, ColumnLayout } from '@adac/core-view';
import { useFormikContext } from 'formik';
import { __ } from '@adac/core-model';
import styled from 'styled-components';
import { EditReportImageFormValues } from './EditReportModal';

const ButtonStyles = styled(Button)`
  width: 33%;
  display: block;
  margin: 0 auto;
`;

interface EditFormButtons {
  onBack: () => void;
}

export function EditFormButtons({ onBack }: EditFormButtons) {
  const {
    isSubmitting, isValid,
  } = useFormikContext<EditReportImageFormValues>();

  return (
    <ColumnLayout ratio="repeat(2, 1fr)" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
      <ButtonStyles
        type="submit"
        cta
        disabled={!isValid || isSubmitting}
        isLoading={isSubmitting}
        style={{ width: '100%' }}
        title={__('Permanently delete components from the file')}
      />

      <ButtonStyles
        type="button"
        cancel
        onClick={onBack}
        title={__('Cancel')}
      />
    </ColumnLayout>
  );
}
