import { useCallback, useEffect, useState } from 'react';
import { ImageReport } from '@adac/core-model';
import { useAuthStore } from '../stores';
import { getReportImages, updateReportImages } from '../networking/report';
import { EditReportImageFormValues } from '../components/cases/caseStatus/EditReportModal';

export function useImageReports(caseToken?: string) {
  const { token: jwt } = useAuthStore();
  const [reports, setReports] = useState<ImageReport | undefined>(undefined);

  const fetchImages = useCallback(async () => {
    if (!jwt || !caseToken) {
      console.warn('Invalid token for user/case');
      return;
    }
    const response = await getReportImages(jwt, caseToken);
    setReports(response);
  }, [jwt, caseToken]);

  useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  function updateReports(updatedValues: EditReportImageFormValues) {
    if (!jwt || !caseToken) {
      console.warn('Invalid token for user/case');
      return;
    }
    updateReportImages(jwt, caseToken, updatedValues);
  }

  return {
    reports,
    setReports,
    updateReports,
  };
}
