/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-extraneous-dependencies */
import {
  List, Datagrid, TextField,
} from 'react-admin';

import {
  __,
} from '@adac/core-model';
import { GenericListActions } from '../common/GenericListActions';
import HolidayFilter from './HolidayFilter';
import { EditButtonWithRole } from '../common/react-admin/EditButtonWithRole';
import { StyledPaginationtForList } from '../StyledPaginationtForList';

const HolidayList = (props: any) => (
  <StyledPaginationtForList>
    <List
      {...props}
      bulkActionButtons={false}
      filters={<HolidayFilter />}
      filterDefaultValues={{ futureHoliday: true }}
      actions={<GenericListActions allowCreate />}
    >
      <Datagrid>
        <TextField
          source="id"
          label={__('Holiday Id')}
          sortable
        />
        <TextField
          source="name"
          label={__('Name')}
          sortable
        />
        <TextField
          source="day"
          label={__('Day')}
          sortable
        />
        <TextField
          source="month"
          label={__('Month')}
          sortable
        />
        <TextField
          source="year"
          label={__('Year')}
          sortable
        />
        <TextField
          source="regionName"
          label={__('Related region')}
          sortable={false}
        />
        <EditButtonWithRole label={__('Edit')} />
      </Datagrid>
    </List>
  </StyledPaginationtForList>
);
export default HolidayList;
