/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  getOneCaseStatusEventName,
  filesNotAvailableStates, __, isCaseStatusEvent, UserRole, allowedStatesForClosing,
  CaseStatusList,
} from '@adac/core-model';
import {
  MapContainer,
  useSocketClient,
  CaseDocuments,
  InvoiceDocumentLink,
  createAddressString,
  extractConfirmedAddressObject,
  CaseStatusEventHistory,
  Loader,
  GeneralButton,
  CaseStatusHeaderPlaceholder,
  FilePlaceholder,
  Separator,
  useCaseDetails,
  EventHistoryCheckboxes,
  DocumentStyles,
  CaseStatusTitle,
  FlexView,
  Icon,
  View,
} from '@adac/core-view';
import { RouteComponentProps } from 'react-router';

import { showNotification } from 'react-admin';
import CustomCard from '../common/CustomCard';
import CaseStatusHeader, { CaseHeaderContent } from './caseStatusComponents/CaseStatusHeader';
import CaseStatusDetails from './caseStatusComponents/CaseStatusDetails';
import CaseStatusComment from './caseStatusComponents/CaseStatusComment';
import CaseStatusSection from './caseStatusComponents/CaseStatusSection';
import { CaseDriverInfo } from './caseStatusComponents/CaseDriverInfo';
import CaseStatusInfoBlock from './caseStatusComponents/CaseStatusInfoBlock';
import { CaseCompanyInfo } from './caseStatusComponents/CaseCompanyInfo';
import { RAShowNotificationProps } from '../common/react-admin/interfaces';
import { getCaseDetails } from '../../dataProvider/getCase';

import { useUserRole } from '../../helpers/useUserRole';
import setClosingCaseAction, { CaseCloseType } from '../../actions/setClosingCase';
import { EditReportModal } from './caseStatus/EditReportModal';

export const Card = styled(CustomCard)`
  display: flex;
  flex-direction: column;
  place-items: initial;
`;

const CustomMapStyles = styled.div`
  height: 240px;
  max-width: 95vw;
  width: calc(100vw - 240px - 30px);
  overflow: hidden;
`;

interface CaseStatusProps extends RAShowNotificationProps, RouteComponentProps<{ caseId: string }> {
  setClosingCase: (props: CaseCloseType) => void;
}

const CaseStatus = (raProps: CaseStatusProps): JSX.Element => {
  const [isEditingDocuments, setIsEditingDocuments] = React.useState(false);
  const socket = useSocketClient();
  const [allLogType, setAllLogType] = React.useState(true);
  const {
    caseDetails, isLoading, refreshCaseData,
    activeEvent, customerCoords, driverCoords,
    confirmedAddress,
  } = useCaseDetails([raProps.match.params.caseId], getCaseDetails);

  const userRole = useUserRole();
  const isCloseable = userRole.names.includes(`${UserRole.MANAGER}`) && allowedStatesForClosing.includes(activeEvent);

  const onCloseButtonClick = async () => {
    if (caseDetails?.case) {
      await raProps.setClosingCase(caseDetails?.case);
    }
  };

  React.useEffect(() => {
    // TODO: evaluate if we can directly use socket data instead of refreshCaseData
    socket.on(getOneCaseStatusEventName(caseDetails?.case?.id), refreshCaseData);
    return () => {
      socket.off(getOneCaseStatusEventName(caseDetails?.case?.id));
    };
  }, [socket, caseDetails, refreshCaseData]);

  const shouldAllowEditDocuments = caseDetails?.status.event === CaseStatusList.CASE_COMPLETED && caseDetails?.case.commissionReport;

  return (
    <Card>
      <CaseStatusHeader>
        <Loader isLoading={isLoading} component={CaseStatusHeaderPlaceholder}>
          <CaseHeaderContent caseDetails={caseDetails}>
            <>
              {isCloseable ? <GeneralButton info onClick={onCloseButtonClick} title={__('Close case')} /> : ''}
            </>
          </CaseHeaderContent>
        </Loader>
      </CaseStatusHeader>

      <CustomMapStyles>
        <MapContainer
          style={{
            height: '240px',
          }}
          customerPosition={customerCoords}
          driverPosition={driverCoords}
          markerPadding={80}
        />
      </CustomMapStyles>

      <CaseStatusDetails
        isLoading={isLoading}
        caseDetails={caseDetails?.case}
      >
        <>
          <CaseStatusInfoBlock area="address" title={__(confirmedAddress ? 'Address given by customer' : 'Customer Address')}><Loader isLoading={isLoading}>{createAddressString(caseDetails?.case)}</Loader></CaseStatusInfoBlock>
          {confirmedAddress && <CaseStatusInfoBlock area="address2" title={__('Confirmed Customer Address')}><Loader isLoading={isLoading}>{createAddressString(extractConfirmedAddressObject(confirmedAddress))}</Loader></CaseStatusInfoBlock>}
        </>
      </CaseStatusDetails>

      <Separator />

      <CaseStatusSection paddingTop="0 !important" paddingBottom="0 !important">

        <CaseCompanyInfo isLoading={isLoading} companyInfo={caseDetails?.companyInfo} />

        <CaseDriverInfo isLoading={isLoading} driverInfo={caseDetails?.driverInfo} />
      </CaseStatusSection>

      <CaseStatusComment
        isLoading={isLoading}
        caseToken={caseDetails?.token}
        caseId={Number(caseDetails?.case?.id)}
        items={caseDetails?.comments}
        refreshCaseData={refreshCaseData}
      />

      <CaseStatusEventHistory
        isLoading={isLoading}
        history={raProps.history}
        items={caseDetails?.logs}
        filteredItems={(allLogType ? caseDetails?.logs : caseDetails?.logs?.filter(i => i.event && isCaseStatusEvent(i.event)))}
      >
        <EventHistoryCheckboxes checked={allLogType} onChange={(value: boolean) => setAllLogType(value)} />
      </CaseStatusEventHistory>

      {shouldAllowEditDocuments && (
        <EditReportModal
          on={isEditingDocuments}
          caseToken={caseDetails?.case.token}
          setIsOpen={setIsEditingDocuments}
          showNotification={raProps.showNotification}
        />
      )}

      {!filesNotAvailableStates.includes(activeEvent) && (
        <DocumentStyles>
          <FlexView>
            <CaseStatusTitle>{__('Informationen über den Kunden')}</CaseStatusTitle>
            {shouldAllowEditDocuments && (
            <View onClick={() => setIsEditingDocuments(true)}>
              <Icon name="edit" color="black" style={{ marginLeft: '10px', cursor: 'pointer' }} />
            </View>
            )}
          </FlexView>
          <Loader isLoading={isLoading} count={4} component={FilePlaceholder}>
            <CaseDocuments
              token={caseDetails?.case?.token}
              commissionReport={caseDetails?.case?.commissionReport}
              commissionExtraDamage={caseDetails?.case?.commissionExtraDamage}
              finalStatusReport={caseDetails?.case?.finalStatusReport?.customer}
            >
              {!!caseDetails?.case?.invoiceDocument && (
                <InvoiceDocumentLink
                  token={caseDetails?.case?.token}
                  {...caseDetails?.case?.invoiceDocument}
                />
              )}
            </CaseDocuments>
          </Loader>
        </DocumentStyles>
      )}
    </Card>
  );
};

const mapStateToPropsCaseStatus = (state: any) => ({
  closingCase: state.closingCase,
});

export default connect(mapStateToPropsCaseStatus, {
  showNotification,
  setClosingCase: setClosingCaseAction,
})(CaseStatus);

