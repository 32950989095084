import { __, ImageReport, SignatureAndPhotos } from '@adac/core-model';
import {
  Text, View,
} from '@adac/core-view';
import styled from 'styled-components';
import { ReportImages } from './ReportImages';
import { ReasonForm } from './ReasonCheckboxes';

const Title = styled.span`
  font-size: ${props => props.theme.font.title};
  font-weight: bold;
  font-family: ${props => props.theme.font.face.bold.slab};

  margin-bottom: 20px;
`;

const HighlightedTitle = styled.span`
  background-color: #FFCC01;
`;

const ReportCarousel = styled(View)`
  margin-bottom: 50px;
`;

const FormStyles = styled(View)`
  margin: 40px;
`;

interface EditReportFormProps {
  reports: ImageReport;
  setReports: (ir?: ImageReport) => void;
}

export function EditReportForm({ reports, setReports }: EditReportFormProps) {
  return (
    <FormStyles>
      {Object.entries(reports).map(
        ([reportType, { photos, signature }]: [string, SignatureAndPhotos]) => (
          <ReportCarousel key={reportType}>
            <Title>
              <span>{__('Editing the PDF File')} </span>
              <HighlightedTitle>{__(reportType)}</HighlightedTitle>
            </Title>
            <Text bold>{__('The following components should be permanently deleted from the file')}</Text>

            <ReportImages
              reports={reports}
              setReports={setReports}
              photos={photos}
              signature={signature}
              reportType={reportType as keyof ImageReport}
            />
          </ReportCarousel>
        ),
      )}
      <ReasonForm />
    </FormStyles>
  );
}
