import { getApiRoutes, ImageReport } from '@adac/core-model';
import { request } from '@adac/core-view';

export const getReportImages = async (jwt: string, caseToken: string) => {
  const response = await request<ImageReport>(getApiRoutes().backoffice.case.reportImages(caseToken), {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  return response;
};

export const updateReportImages = async (jwt: string, caseToken: string, data: any) => {
  const response = await request(getApiRoutes().backoffice.case.updateReportImages(caseToken), {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
    data,
  });
  return response;
};
